const defaultImgs = {
  avatar: '/img/default-user-avatar.png',
  casino: '/img/default-image.webp',
  ico: '/img/default-image.webp',
  airdrop: '/img/default-image.webp',
  slide: '/img/default-image-big.webp',
  game: '/img/default-image.webp',
  banner: '/img/default-image-wide.webp',
  currency: ''
}

export default {
  methods: {
    getImage(imageId, type = 'avatar', width = '200', height = width) {
      // TODO: Ask Vova about default-img for casinos, icos
      if (!imageId) { return defaultImgs[type] }
      return `${process.env.VUE_APP_IMG_API_URL}/${imageId}/${width}/${height}`
    },
    getContentfulImage(imageUrl, type = 'avatar') {
      if (!imageUrl) { return defaultImgs[type] }
      return imageUrl
    }
  }
}
