import api from '@/api'

const bookmarkCasino = {
  methods: {
    async toggleCasinoSubscription() {
      if (!this.getUserDB) {
        this.$router.push({
          name: 'Login',
          query: { redirect: this.$route.path }
        })

        return
      }

      const {
        data,
        error
      } = await api.likes.toggleLike(
        this.casinoData.id,
        'casino'
      )

      if (error) return

      this.$emit('updateBookmark', {
        id: this.casinoData.id,
        data
      })
    }
  }
}

export default bookmarkCasino
