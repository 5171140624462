<template>
  <article>
    <ul :class="['list', {'read-only': readOnly}]">
      <li
        v-for="starValue in length"
        :key="starValue"
        :class="['item', { 'active': starValue <= choosenRating }]"
      >
        <button
          :aria-label="`Rating ${starValue} of ${length}`"
          type="button"
          :disabled="readOnly"
          @click="toRate(starValue)"
        >
          <BRIcon
            :height="height"
            :width="width"
            class="icon"
            icon="rating"
            :style="{ color: getHexagonColor(starValue) }"
          />
        </button>
      </li>
    </ul>
  </article>
</template>

<script>
import ratingColor from '@/mixins/ratingColor'
import BRIcon from '@/components/BRComponents/BRIcon.vue'

export default {
  name: 'BRRatingHexagon',
  components: {
    BRIcon
  },
  mixins: [ratingColor],
  props: {
    currentRating: {
      type: Number,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '35'
    },
    height: {
      type: String,
      required: false,
      default: '40'
    }
  },
  data() {
    return {
      choosenRating: this.readOnly ? Math.round(this.currentRating) : 0,
      length: 5
    }
  },
  watch: {
    currentRating() {
      this.choosenRating = Math.round(this.currentRating)
    }
  },
  mounted() {
    this.$emit('changeRatingValue', this.choosenRating)
  },
  methods: {
    toRate(starValue) {
      const isEqual = this.choosenRating === starValue
      this.choosenRating = isEqual && this.choosenRating !== 1 ? starValue - 1 : starValue
      this.$emit('changeRatingValue', this.choosenRating)
    },
    getHexagonColor(starValue) {
      return starValue <= this.choosenRating ? this.getRatingColor(this.currentRating) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  padding: 0;
  gap: 5px;

  &:hover {
    .item {
      color: currentColor;
    }

    .item:not(.active) {
      color: currentColor;
      opacity: 0.7;
    }
  }
}

.item {
  width: 35px;
  height: 40px;
  color: var(--secondary-icon-color);
  cursor: pointer;

  &:hover {
    ~ .item {
      &:not(.active) {
        color: var(--secondary-icon-color);
        opacity: 1;
      }
    }
  }
}

.item.active {
  .icon {
    color: var(--primary-icon-color);
  }
}

.read-only {
  pointer-events: none;
}
</style>
