<template>
  <div :style="cssVars">
    <div class="progress" />
    <p class="progress-info">
      <span class="text"><slot /></span>
      <span class="percents">{{ `${progress}%` }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'BRRatingProgress',
  props: {
    progress: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: false,
      default: 10
    }
  },
  computed: {
    cssVars() {
      return {
        '--rating-progress': `${this.progress}%`,
        '--rating-progress-height': `${this.height}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  margin-bottom: 8px;
  border-radius: var(--border-radius-small);
  width: 100%;
  height: var(--rating-progress-height);
  background-color: var(--quinary-bg-color);
}

.progress::after {
  content: "";
  position: absolute;
  border-radius: var(--border-radius-small);
  width: var(--rating-progress);
  height: 100%;
  max-width: 100%;
  background-color: var(--main-bg-color);
}

.progress-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  font-size: var(--font-xs);
}

.percents {
  font-size: var(--font-xs);
  color: var(--primary-text-color);
}
</style>
